@font-face {
  font-family: "NeoSansProLight";
  src: url("./fonts/NeoSansProLight.ttf") format("truetype");
}

@font-face {
  font-family: "NeoSansProRegular";
  src: url("./fonts/NeoSansProRegular.ttf") format("truetype");
}

@font-face {
  font-family: "NeoSansProMedium";
  src: local("NeoSansProMedium"),
    url("./fonts/NeoSansProMedium.OTF") format("truetype");
}